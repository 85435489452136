import React from "react";
import { Header, Footer, Faq,ImageComponent } from "../../Common";
import Images from "../../Constant/Images";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./Aboutus.css";
import { Helmet } from "react-helmet"; 

export default function Aboutus() {
  const about = [
    {
      id: 1,
      image: Images.communication,
      tittle: "Communication",
      description:
        " Communicate frequently to make quick decisions and prevent misunderstandings",
    },
    {
      id: 2,
      image: Images.adaptability,
      tittle: "Adaptability",
      description:
        " Seamlessly evolving with user needs and technological advancements",
    },
    {
      id: 3,
      image: Images.value,
      tittle: "Value centric",
      description:
        " Provide quality product and services at a lowest cost possible ",
    },
    {
      id: 4,
      image: Images.collaboration,
      tittle: "Collaboration",
      description:
        " Fostering a culture of collaboration, teamwork, and mutual respect",
    },
    {
      id: 5,
      image: Images.hr,
      tittle: "Simplicity",
      description:
        " Simplify complex ideas to create solutions that are easier to understand.",
    },
  ];
  return (
    <div>
       <Helmet>
        <title>About Neelgai | Software Development, AI, Mobile Apps & Cybersecurity</title>
        <meta
          name="description"
          content="Learn about Neelgai, a software development company dedicated to providing fast and cost-effective tech services. Discover our mission, values, and the challenges we solve for businesses."
        />
        <meta
          name="keywords"
          content="Neelgai, software development, about us, tech services, mission, values"
        />
        <link rel="canonical" href="https://www.neelgai.com/about" />
        {/* Add Open Graph and Twitter Card tags as needed */}
        <meta property="og:title" content="About Us - Neelgai" />
        <meta property="og:description" content="Discover Neelgai's mission and values in software development." />
        <meta property="og:url" content="https://www.neelgai.com" />
        <meta property="og:image" content="https://www.neelgai.com/static/media/neelgai-resource.33b90f763a3d91cf9f35.png" /> {/* Replace with an actual image URL */}
        <meta property="twitter:card" content="https://www.neelgai.com/static/media/neelgai-resource.33b90f763a3d91cf9f35.png" />
        <meta property="twitter:title" content="About Us - Neelgai" />
        <meta property="twitter:description" content="Learn about Neelgai and our software development services." />
        <meta property="twitter:image" content="https://www.neelgai.com/static/media/neelgai-resource.33b90f763a3d91cf9f35.png" /> {/* Replace with an actual image URL */}
      </Helmet>
      <Header />
      <div>
      <h1 style={{ position: 'absolute', opacity: 0 }}>
        About us
      </h1>
        <section className="section-about">
          <div className="mission-section">
            <div className="container">
              <div className="row mt-md-5 pt-md-5 pb-3">
                <div className="col-lg-6 text-start mt-5 pt-5 pt-lg-0 my-lg-auto">
                  <h1 className="about-title text-start pb-4 ">
                    We’re on a mission to provide fast and cost-effective tech
                    services.
                  </h1>

                  <p className=" text-desc text-start  pb-4 ">
                    Struggling to initiate your product ideas due to limited
                    tech expertise, unproductive ad hoc works, and tight
                    deadlines? We are here to take away your tech burdens so
                    that you can focus on what truly matters to the business
                    without worrying about time and cost.
                  </p>
                  <a className="talk-t" href="/contact">
                    <span className="request-btn ">Book a call</span>
                  </a>
                </div>

                <div className="col-lg-5  mt-5">
                  
                  <ImageComponent images={[{ url: `${Images.about_test}`}]}
                                         defaultImageUrl={`${Images.about_test}`}
                                         className="img-fluid "
                                         alt="Neelgai-about"
                                         />
                </div>
              </div>
            </div>
          </div>
          <div className="svg-about"></div>

          <section className=" pt-5 container about-container mt-5">
            <h1 className="title-skill ">Current Market Problems</h1>

            <Tabs
              defaultActiveKey="build"
              id="justify-tab-example"
              className="mb-3"
              justify
            >
              <Tab
                eventKey="build"
                title={
                  <div className=" skill-tab">
                    <div>Problem</div>
                    <div>#1</div>
                  </div>
                }
              >
                <div className="row pt-5">
                  <div className="col-md-6 my-auto text-start">
                    <h3>Manage non-core functions</h3>
                    <p className="pt-3">
                      Companies find it challenging to manage non-core functions
                      efficiently while trying to focus on their primary areas
                      of expertise.
                    </p>
                  </div>
                  <div className="col-md-6">
                   
                    <ImageComponent images={[{ url: `${Images.target}`}]}
                                         defaultImageUrl={`${Images.target}`}
                                         className="img-fluid img-problem "
                                         alt="Manage function"
                                         />
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="technologies"
                title={
                  <div className="skill-tab">
                    <div>Problem</div>
                    <div>#2</div>
                  </div>
                }
              >
                <div className="row pt-5">
                  <div className="col-md-6 my-auto text-start">
                    <h3>Challenge to retain talents</h3>
                    <p className="pt-3">
                      They have hard time retaining specialized talent particularly for niche skills that are not generally available in the local talent pool.
                    </p>
                  </div>
                  <div className="col-md-6">
                   
                    <ImageComponent images={[{ url: `${Images.quit}`}]}
                                         defaultImageUrl={`${Images.quit}`}
                                         className="img-fluid img-problem "
                                         alt="Challenges"
                                         />
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="practices"
                title={
                  <div className="skill-tab">
                    <div>Problem</div>
                    <div>#3</div>
                  </div>
                }
              >
                <div className="row pt-5">
                  <div className="col-md-6 my-auto text-start">
                    <h3>Round the clock support</h3>
                    <p className="pt-3">
                      They struggle to provide round-the-clock support or maintain a global presence due to time zone differences.
                    </p>
                  </div>
                  <div className="col-md-6">
                   
                    <ImageComponent images={[{ url: `${Images.people_probs}`}]}
                                         defaultImageUrl={`${Images.people_probs}`}
                                         className="img-fluid img-problem "
                                         alt="Round Clock"
                                         />
                  </div>
                </div>
              </Tab>
              {/* <Tab
                eventKey="contact"
                title={
                  <div className="skill-tab">
                    <div>Problem</div>
                    <div>#4</div>
                  </div>
                }
              >
                <div className="row pt-5">
                  <div className="col-md-6 my-auto text-start">
                    <h3>Manage non-core functions</h3>
                    <p className="pt-3">
                      Companies find it challenging to manage non-core functions
                      efficiently while trying to focus on their primary areas
                      of expertise.
                    </p>
                  </div>
                  <div className="col-md-6">
                    <img
                      src={Images.service_procurement}
                      alt="image-blog"
                      className="img-fluid img-problem"
                    />
                  </div>
                </div>
              </Tab> */}
            </Tabs>
          </section>
          <div className="container pb-5 mb-5">
            <div className="text-center pt-5">
              <h1 className="explore-title py-5 my-5">
                We built Neelgai to solve these challenges
              </h1>
              <p className="pro-service pt-5">NEELGAI'S</p>
              <div className="col-md-6 mx-auto">
                <h1 className="pro-description px-md-5">Guiding principles</h1>
                <p className="pt-2">
                  These values guide our decisions and actions, ensuring that we
                  create a positive and lasting impact on our clients.
                </p>
              </div>
            </div>
            <div className="row justify-content-center">
              {about.map((elem, index) => (
                <div key={index} className=" col-lg-4  px-0 mt-3  mt-md-5 ">
                  <div className=" box-about m-3  ">
                    <div className="text-center icon-skill">
                      <img
                        src={elem.image}
                        alt="image-blog"
                        className="img-fluid "
                      />
                    </div>
                    <div className="text-center abt-topic py-2">
                      {elem.tittle}
                    </div>
                    <div className="text-center skill-more">
                      {elem.description}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* <Team /> */}
          </div>
          <div className="svg-bottom "></div>
          <Faq />
        </section>
      </div>
      <Footer />
    </div>
  );
}
