import React from "react";
import "./Faq.css";
import Accordion from "react-bootstrap/Accordion";

export default function Faq() {
  const faq = [
    {
      id: 1,
      question: "How do we reach out to you?",
      answer: `You can either contact us via our <a href="https://www.neelgai.com/contact">contact-us</a>  form or send us an email to <a href="mailto:support@neelgai.com">support@neelgai.com</a>`,
    },
    {
      id: 2,
      question: "Where is the company located?",
      answer: `Currently, our operations span the United States and Nepal. Our development team is predominantly based in Nepal, leveraging local talent, while our executive team is distributed across various locations within the United States. This setup allows us to combine cost-efficiency with strategic leadership presence, ensuring effective project management and execution across both regions.`,
    },
    {
      id: 3,
      question: "What kind of developers would work on the project?",
      answer:
        "We have an expert in-house team comprising Frontend, Backend, Mobile App, AI/ML full-stack developers, as well as dedicated DevOps and Security specialists most of whom have 5+ years of professional work experiences.",
    },
    {
      id: 4,
      question: "What tools/tech stack is your team good at?",
      answer:
        "Our team excels in a diverse tech stack including Python, Java, React.js, AWS, TensorFlow, and cybersecurity tools. With expertise in web and mobile development, AI/ML frameworks, cloud platforms, and DevOps, we deliver innovative solutions tailored to client needs across various industries, ensuring robust and effective project outcomes.",
    },
    {
      id: 5,
      question: "What kind of projects do you generally work on?",
      answer:
        "Some of the projects that we have worked on include but not limited to: </br></br> <div> - Developing a new e-commerce platform for a major retail client.</div> <div> - Implementing a CRM system for a healthcare provider to improve patient management.</div><div> - Designing and deploying a cloud infrastructure solution for a financial services company.</div><div> - Launching a mobile app for a transportation company to enhance customer experience.</div><div> - Conducting a cybersecurity audit and implementing recommendations for a government agency.</div><div> - Applying AI and machine learning algorithms to optimize business processes for a tech startup.</div>",
    },
  ];
  return (
    <div className="pb-5 ps-4  faq-box ">
      <div className="container">
        <h3 className="text-start text-faq py-4">FAQ</h3>
        <h3 className="text-start faq-desc pb-5">
          Simple answers to popular questions
        </h3>
        <div className="row justify-content-center">
          <div className="col-md-8">
            <Accordion defaultActiveKey="0">
              {faq.map((elem, index) => (
                <Accordion.Item eventKey={index} key={index}>
                  <Accordion.Header>
                    <span className="me-3 item-num">{elem.id} </span>
                    <span className="text-question ms-1">{elem.question}</span>
                  </Accordion.Header>
                  <Accordion.Body
                    className="text-start ms-md-4 text-acco"
                    dangerouslySetInnerHTML={{ __html: elem.answer }}
                  ></Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
}
